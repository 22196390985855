<template>
  <b-modal
    id="spsadt-guide-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal(false)"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="alreadyUpdated"
          >Atualizar guia SP/SADT - Nº {{ form.provider_guide }}</span
        >
        <span v-else>Nova guia SP/SADT - Nº {{ form.provider_guide }}</span>
      </div>
      <div class="inline-header-actions">
        <TopBarButtonsModule
          v-if="invoicing_tiss_setting?.tiss_using_guide_type?.other_expenses_guide || tissGuide?.guide?.expensables?.length"
          :form="form"
          :tissGuide="tissGuide"
          @startOtherExpensesGuide="startOtherExpensesGuide"
          @openOtherExpensesGuide="startOpenOtherExpensesGuide"
        />
        <span class="icon-box"
          ><Close class="icon stroke" @click="closeModal(true)"
        /></span>
      </div>
    </div>

    <div class="modal-content around-content">
      <GuideDataModule
        :form="form"
        :fields="{
          guide_issue_date: true,
          tiss_invoice_id: true,
          request_id: true,
          situation: true
        }"
        :validated="validated"
        :readonly="readonly"
        :healthPlanId="healthPlan?.id ?? null"
        :tissBatchId="tissGuide.tiss_batch_id"
        :tissGuide="tissGuide"
      />

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados da operadora</p>
        </b-col>

        <b-col cols="4">
          <label for="ans">Registro ANS</label>
          <b-form-input maxlength="6" v-model="form.ans" />
        </b-col>

        <b-col cols="4">
          <label for="provider_guide">Nº da guia no prestador</label>
          <ProviderGuideInput
            :providerGuide="form.provider_guide"
            :readonly="providerGuideReadonly"
            :validateProviderGuide="validateProviderGuide"
            type="sp_sadt"
            :validated="validated"
            :guideNumberExistsInBatch="guideNumberExistsInBatch"
            @update:provider-guide="form.provider_guide = $event"
          />
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <label for="main_tab_number">Nº da guia principal</label>
            <b-form-input
              maxlength="20"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.main_tab_number"
              :state="validProp('main_tab_number')"
            />
            <b-form-invalid-feedback :state="validProp('main_tab_number')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="3" class="mt-3">
          <b-form-group>
            <label for="authorization_date">Data da autorização</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.authorization_date"
              format="DD/MM/YYYY"
              :clearable="false"
              class="full"
              :disabled="readonly"
              :state="validProp('authorization_date')"
            />
            <b-form-invalid-feedback :state="validProp('authorization_date')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="2" class="mt-3 input-password">
          <label for="password">Senha</label>
          <b-input-group>
            <b-form-input
              maxlength="20"
              placeholder="Descrever"
              autocomplete="off"
              type="text"
              v-model="form.password"
              :readonly="readonly"
              :state="validProp('password')"
            />
            <b-form-invalid-feedback :state="validProp('password')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-input-group>
        </b-col>

        <b-col cols="3" class="mt-3">
          <b-input-group>
            <label for="password_expiration_date">Validade da senha</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.password_expiration_date"
              format="DD/MM/YYYY"
              :clearable="false"
              class="full"
              :disabled="readonly"
              :state="validProp('password_expiration_date')"
            />
            <b-form-invalid-feedback
              :state="validProp('password_expiration_date')"
            >
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-input-group>
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="operator_guide_number"
            >Nº da guia atribuida pela operadora</label
          >
          <b-form-input
            maxlength="20"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.operator_guide_number"
            :readonly="readonly"
          />
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do beneficiário (Paciente)</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group>
            <label for="wallet_number">Nº da carteira</label>
            <b-form-input
              maxlength="20"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.wallet_number"
              :readonly="readonly"
              :state="validProp('wallet_number')"
            />
            <b-form-invalid-feedback :state="validProp('wallet_number')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group>
            <label for="wallet_expiration_date">Validade da carteira</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.wallet_expiration_date"
              format="DD/MM/YYYY"
              :clearable="false"
              class="full"
              :disabled="readonly"
            />
            <b-form-invalid-feedback :state="validProp('wallet_expiration_date')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group>
            <label for="beneficiary_name">Nome do beneficiário</label>
            <b-form-input
              maxlength="70"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.beneficiary_name"
              :readonly="true"
              :state="validProp('beneficiary_name')"
            />
            <b-form-invalid-feedback :state="validProp('attendance_character')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="mt-3">
          <label for="national_health_card">Cartão Nacional de Saúde</label>
          <b-form-input
            maxlength="15"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.national_health_card"
            :readonly="readonly"
          />
        </b-col>

        <b-col cols="6" class="mt-3">
          <label for="newborn_care">Recém-nascido</label>
          <div class="mt-3">
            <label class="checkbox" align="center">
              <input
                type="checkbox"
                v-model="form.newborn_care"
                :disabled="readonly || (patient && patient.birthday)"
              />
              <span
                class="checkmark"
                :class="{
                  'check-disable': readonly || (patient && patient.birthday)
                }"
              >
                <Check />
              </span>
            </label>
          </div>
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do Solicitante</p>
        </b-col>

        <ContractedField
          :cols="7"
          label="Contratado solicitante"
          id="contractor_name_requesting"
          :readonly="readonly"
          :vModel="form.contractor_name_requesting"
          :validated="validated"
          :contracteds="contracteds"
          @select-contracted="selectContracted"
        />

        <b-col cols="5">
          <label for="operator_code">Cód. na oper. / CPF / CNPJ</label>
          <b-form-input
            id="operator_code"
            maxlength="14"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.operator_code"
            :readonly="readonly"
          />
        </b-col>
        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do profissional solicitante</p>
        </b-col>
        <b-col cols="3" class="mt-3">
          <b-form-group>
            <SimpleSelect
              label="Tipo do profissional"
              v-model="form.requesting_professional_type"
              :options="requestingProfessionalTypes"
              @change="requestingProfessionalTypeChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" class="mt-3" v-show="!isExternalRequestingProfessional">
          <label for="professional_name">Nome do profissional</label>
          <ProfessionalFieldModule
            :settingsId="form.invoicing_tiss_setting_id"
            :form="form"
            :multiselect="true"
            :readonly="readonly"
            :fillProfessionalData="fillProfessionalData"
            @change-professionals="professionals = $event"
            :reloadValues="reloadValues"
          />
        </b-col>
        <b-col cols="6" class="mt-3" v-show="isExternalRequestingProfessional">
          <label for="external_professional_name">Nome do profissional</label>
          <b-form-input
            id="external_professional_name"
            maxlength="70"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.requesting_professional_name"
          />
        </b-col>

        <b-col cols="3" class="mt-3" :key="reloadValues">
          <label for="professional_council">Conselho</label>
          <multiselect
            v-model="form.professional_council"
            placeholder="Selecionar"
            :options="professionalCouncils"
            track-by="value"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
            :disabled="!isExternalRequestingProfessional"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              <span class="d-inline-block text-truncate width-80">
                {{ option.label }}
              </span>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>

        <b-col cols="2" class="mt-3">
          <label for="professional_council_registration">Nº conselho</label>
          <b-form-input
            maxlength="15"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.professional_council_registration"
            :readonly="!isExternalRequestingProfessional"
          />
        </b-col>

        <b-col cols="2" class="mt-3">
          <label for="uf">UF</label>
          <multiselect
            v-model="form.uf"
            placeholder="Selecionar"
            :options="statesUf"
            track-by="value"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
            :disabled="!isExternalRequestingProfessional"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.label }}
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>

        <b-col cols="3" class="mt-3">
          <label for="cbo_code">CBO</label>
          <multiselect
            v-model="form.cbo_code"
            placeholder="Selecionar"
            :options="cbos"
            track-by="value"
            label="label"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
            :disabled="!isExternalRequestingProfessional"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              <span class="d-inline-block text-truncate width-95">
                {{ option.value }}
              </span>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>

        <b-col cols="5" class="mt-3">
          <b-form-group>
            <label for="cbo_description">Descrição CBO</label>
            <b-input
              id="cbo_description"
              maxlength="100"
              placeholder="Descrever"
              autocomplete="off"
              :value="form.cbo_code?.label?.replace(/\d+/g, '')"
              readonly
            >
              {{ form.cbo_description }}
            </b-input>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">
            Dados da Solicitação/Procedimentos ou Itens Assistenciais
            Solicitados
          </p>
        </b-col>

        <b-col cols="3">
          <b-form-group>
            <label for="attendance_character">Caráter do Atendimento</label>
            <multiselect
              v-model="form.attendance_character"
              placeholder="Selecionar"
              :options="attendancesCharacter"
              track-by="value"
              label="label"
              :allow-empty="true"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="validProp('attendance_character')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group>
            <label for="request_date">Data da solicitação</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.request_date"
              format="DD/MM/YYYY"
              :clearable="false"
              class="full"
              :disabled="readonly"
              :state="validProp('request_date')"
            />
            <b-form-invalid-feedback :state="validProp('request_date')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group>
            <label for="clinical_indication">Indicação Clínica</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.clinical_indication"
              :readonly="readonly"
              :state="validProp('clinical_indication')"
            />
            <b-form-invalid-feedback :state="validProp('clinical_indication')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <ProceduresRequestsModule
        class="mt-3"
        :materialsProcedures="form.materialsProcedures"
        guide="SP/SADT"
        :settingsId="form.invoicing_tiss_setting_id"
        :readonly="readonly"
        :clinicHealthPlanId="this.clinicHealthPlanId"
        :allowedTypes="[
          ITEM.ITEM_TYPE_PROCEDURE,
          ITEM.ITEM_TYPE_APPOINTMENT,
          ITEM.ITEM_TYPE_EXAM,
          ITEM.ITEM_TYPE_RETURN,
          ITEM.ITEM_TYPE_TELEMEDICINE,
          hasSurgicalCenter ? ITEM.ITEM_TYPE_SURGICAL : null,
        ]"
      />

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do Contratado Executante</p>
        </b-col>

        <b-col cols="3">
          <label for="contracted_code_type">Tipo do código contratado</label>
          <multiselect
            v-model="form.contracted_code_type"
            placeholder="Selecionar"
            :options="contractedCodeTypes"
            track-by="value"
            label="label"
            :allow-empty="true"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
            :disabled="readonly"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.label }}
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>

        <ContractedField
          :cols="4"
          label="Contratado executante"
          id="performing_contractor_name"
          :readonly="readonly"
          :vModel="form.performing_contractor_name"
          :validated="validated"
          :contracteds="contracteds"
          @select-contracted="selectContractor"
        />

        <b-col cols="3">
          <label for="contracted_operator_code">Código na Operadora</label>
          <b-form-input
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.contracted_operator_code"
            :readonly="readonly"
            v-mask="mask"
          />
        </b-col>

        <b-col cols="2">
          <label for="contractor_cnes">Código CNES</label>
          <b-form-input
            maxlength="7"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.contractor_cnes"
            :readonly="readonly"
          />
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do Atendimento</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group>
            <label for="attendance_type">Tipo de Atendimento</label>
            <multiselect
              v-model="form.attendance_type"
              placeholder="Selecionar"
              :options="attendanceTypes"
              track-by="value"
              label="label"
              :allow-empty="true"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>

              <template slot="noOptions"> Nenhuma opção </template>

              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="validProp('attendance_type')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <label for="accident_indication">Indicação de acidente</label>
          <multiselect
            v-model="form.accident_indication"
            placeholder="Selecionar"
            :options="accidentIndications"
            track-by="value"
            label="label"
            :allow-empty="true"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
            :disabled="readonly"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.label }}
            </template>

            <template slot="noOptions"> Nenhuma opção </template>

            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
            <b-form-invalid-feedback :state="validProp('accident_indication')">
              Campo obrigatório
            </b-form-invalid-feedback>
        </b-col>

        <b-col cols="3">
          <label for="appointment_type">Tipo de Consulta</label>
          <multiselect
            v-model="form.appointment_type"
            placeholder="Selecionar"
            :options="consultationTypes"
            track-by="value"
            label="label"
            :allow-empty="true"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
            :disabled="readonly"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.label }}
            </template>

            <template slot="noOptions"> Nenhuma opção </template>

            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>

        <b-col cols="3">
          <label for="closing_reason">Motivo de Encerramento</label>
          <multiselect
            v-model="form.closing_reason"
            placeholder="Selecionar"
            :options="closingReasons"
            track-by="value"
            label="label"
            :allow-empty="true"
            :showLabels="false"
            :showNoResults="false"
            class="with-border"
            :disabled="readonly"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              <span class="d-inline-block text-truncate width-95">
                {{ option.label }}
              </span>
            </template>

            <template slot="noOptions"> Nenhuma opção </template>

            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>
      </b-row>
      <b-row v-show="xml_version && xml_version[0] === '4'">
        <b-col cols="3">
          <b-form-group>
            <label for="attendance_regime">Regime de atendimento</label>
            <multiselect
              id="attendance_regime"
              v-model="form.attendance_regime"
              placeholder="Selecionar"
              :options="attendanceRegimes"
              track-by="value"
              label="label"
              :allow-empty="true"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-95">
                  {{ option.label }}
                </span>
              </template>

              <template slot="noOptions"> Nenhuma opção </template>

              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="validProp('attendance_regime')">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="3" v-show="xml_version && xml_version[0] === '4'">
          <b-form-group>
            <label for="occupational_health">Saúde Ocupacional</label>
            <multiselect
              id="occupational_health"
              v-model="form.occupational_health"
              placeholder="Selecionar"
              :options="occupationalHealthOptions"
              track-by="value"
              label="label"
              :allow-empty="true"
              :showLabels="false"
              :showNoResults="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-95">
                  {{ option.label }}
                </span>
              </template>

              <template slot="noOptions"> Nenhuma opção </template>

              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="hr" />

      <ProceduresModule
        :key="reloadValues"
        guide="SP/SADT"
        :form="form"
        :invoicingTissSetting="invoicing_tiss_setting"
        :tissGuideId="tissGuide?.id"
        :settingsId="form.invoicing_tiss_setting_id"
        :participationPercentage="
          invoicing_tiss_setting?.tiss_participation_percentage
        "
        :specialCases="
          invoicing_tiss_setting?.tiss_participation_percentage_special_case
        "
        :readonly="readonly"
        :beneficiariesList="beneficiariesList"
        @addOrUpdateTissGuideItem="addOrUpdateTissGuideItem"
        @deleteTissGuideItem="deleteTissGuideItem"
      />

      <div class="row">
        <div class="col-12 mt-3">
          <label for="observation">Observação / Justificativa</label>
          <b-form-textarea
            maxlength="500"
            placeholder="Descrever"
            rows="2"
            max-rows="5"
            autocomplete="off"
            v-model="form.observation"
            :readonly="readonly"
          />
        </div>

        <div class="col-4 mt-3">
          <label for="total_value_procedures"
            >Valor total de procedimentos</label
          >
          <money
            :value="totalProcedures()"
            class="form-control"
            readonly
            :key="reloadValues"
          />
        </div>

        <div class="col-4 mt-3">
          <label for="total_amount_fess_and_rents"
            >Valor total de taxas e aluguéis</label
          >
          <money :value="totalByCD('07')" class="form-control" readonly />
        </div>

        <div class="col-4 mt-3">
          <label for="total_value_materials">Valor total de materiais</label>
          <money :value="totalByCD('03')" class="form-control" readonly />
        </div>

        <div class="col-3 mt-3">
          <label for="total_value_opme">Total de OPME</label>
          <money :value="totalByCD('08')" class="form-control" readonly />
        </div>

        <div class="col-3 mt-3">
          <label for="total_value_medicines">Total de medicamentos</label>
          <money :value="totalByCD('02')" class="form-control" readonly />
        </div>

        <div class="col-4 mt-3">
          <label for="total_value_medicinal_gases"
            >Total de Gases Medicinais</label
          >
          <money :value="totalByCD('01')" class="form-control" readonly />
        </div>

        <div class="col-2 mt-3">
          <label for="final_amount">Total Geral</label>
          <money
            :value="total()"
            class="form-control"
            readonly
            :key="reloadValues"
          />
        </div>
      </div>

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-2"
          variant="outline-primary"
          outline
          v-if="tissGuide?.id"
          @click="print"
          v-b-tooltip.hover
          title="A impressão utiliza a guia salva, não refletindo as edições não salvas!"
        >
          Imprimir guia
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="updateTissGuide"
          :disabled="alreadySent"
          v-if="tissGuide?.id && !readonly"
        >
          Atualizar guia
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="createGuide"
          :disabled="alreadySent"
          v-else-if="!readonly"
        >
          Salvar guia
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import { getCurrentClinic } from '@/utils/localStorageManager'
import professionalFieldMixin from '@/mixins/professionalFieldMixin'
import { TissBatch } from '@/modules/tiss/manageGuides/utils/statuses'
import * as ITEM from '@items/utils/constants'
import { showFeature } from '@/utils/permissionsHelp';

export default {
  name: 'SPSADTGuideModal',
  components: {
    Check: () => import('@/assets/icons/check.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    ContractedField: () =>
      import('@/components/Tiss/Guides/Modules/ContractedField'),
    GuideDataModule: () => import('./Modules/GuideDataModule'),
    ProceduresModule: () => import('./Modules/ProceduresModule'),
    ProceduresRequestsModule: () =>
      import('./Modules/MaterialsProceduresModule'),
    ProfessionalFieldModule: () => import('./Modules/ProfessionalFieldModule'),
    ProviderGuideInput: () =>
      import('@/components/Tiss/Generic/ModalComponents/ProviderGuideInput'),
    TopBarButtonsModule: () => import('./Modules/TopBarButtonsModule'),
    SimpleSelect: () => import('@/components/General/SimpleSelect')
  },
  props: {
    patient: Object,
    healthPlan: Object,
    clinicHealthPlanId: String || null,
    invoicing_tiss_setting: Object || null,
    appointmentId: String,
    required_fields: Object,
    tissGuide: Object,
    guideNumber: Number,
    providerGuideReadonly: Boolean,
    validateProviderGuide: Function,
    guideNumberExistsInBatch: Boolean,
    guideNumberExists: Boolean,
    providerGuideUpdated: Number,
    contracteds: Array
  },
  mounted() {
    this.form = this.getDefaultForm()
  },
  mixins: [professionalFieldMixin('form', 'tissGuide', 'reloadValues')],
  data() {
    return {
      ITEM,
      clinic: getCurrentClinic(),
      professionalCouncils: require('./../Data/professionalCouncils.json'),
      statesUf: require('./../Data/statesUf.json'),
      cbos: require('./../Data/cbos.json'),
      attendancesCharacter: require('./../Data/attendancesCharacter.json'),
      contractedCodeTypes: require('./../Data/contractedCodeTypes.json'),
      attendanceTypes: {},
      accidentIndications: require('./../Data/accidentIndications.json'),
      consultationTypes: require('./../Data/consultationTypes.json'),
      closingReasons: require('./../Data/closingReasons.json'),
      attendanceRegimes: require('./../Data/attendanceRegimes.json'),
      occupationalHealthOptions: require('./../Data/occupationalHealthOptions.json'),
      requestingProfessionalTypes: require('./../Data/requestingProfessionalTypes.json'),
      specialCoverages: require('./../Data/specialCoverages.json'),
      alreadySent: false,
      validated: false,
      form: {
        items: [],
        professionals: []
      },
      otherExpenseItems: [],
      xml_version: null,
      canCloseModal: false,
      reloadValues: 1,
      beneficiariesList: [],
      healthPlanRequiredFields: {},
    }
  },
  computed: {
    ...mapState('manageGuides', ['requiredFields']),
    hasSurgicalCenter() {
      return showFeature('Centro cirúrgico')
    },
    readonly() {
      return Boolean(
        this.tissGuide?.id &&
          (['Finalizada'].includes(this.tissGuide.situation) ||
            (this.tissGuide.tiss_batch_id &&
              this.tissGuide.tiss_batch?.status !==
                TissBatch.BATCH_STATUS_TO_SENT))
      )
    },
    mask() {
      if (!this.form.contracted_code_type) return '##############'
      return (
        {
          'CNPJ do contratado': '##.###.###/####-##',
          'CPF do contratado': '###.###.###-##',
          default: '##############'
        }[this.form.contracted_code_type.value] || '##############'
      )
    },
    alreadyUpdated() {
      return (
        this.tissGuide.id &&
        this.tissGuide?.guide?.created_at !== this.tissGuide?.guide?.updated_at
      )
    },
    isExternalRequestingProfessional() {
      return this.form.requesting_professional_type === 'external'
    }
  },
  methods: {
    showFeature,
    getDefaultForm() {
      return {
        id: null,
        patient_id: this.patient.id,
        clinic_id: this.clinic.id,

        invoicing_tiss_setting_id: this.invoicing_tiss_setting?.id,
        appointment_id: this.appointmentId,
        requesting_professional_type: 'internal',

        procedureRequests: [],

        tiss_guide_id: null,
        procedure_id: null,
        bank_account_id: this.invoicing_tiss_setting?.bank_account_id,

        request_id: null,
        situation: null,
        tiss_invoice_id: null,

        ans: this.healthPlan?.ans_code,
        guide_issue_date: this.moment().toDate(),
        provider_guide: this.guideNumber,
        main_tab_number: null,
        authorization_date: null,
        password: null,
        password_expiration_date: null,
        operator_guide_number: null,

        wallet_number: null,
        wallet_expiration_date: null,
        beneficiary_name: null,
        national_health_card: null,
        newborn_care: false,
        professional_selected: null,
        professional_id: null,
        operator_code: this.invoicing_tiss_setting?.hired_executant_code,
        contractor_name_requesting: this.invoicing_tiss_setting?.hired_executant_name,
        professional_name_performing: null,
        professional_council: null,
        professional_council_registration: null,
        uf: null,
        cbo_code: null,
        attendance_character: null,
        request_date: null,
        clinical_indication: null,
        contracted_code_type: this.invoicing_tiss_setting?.provider_code,
        contracted_operator_code: this.invoicing_tiss_setting?.hired_executant_code,
        performing_contractor_name: this.invoicing_tiss_setting?.hired_executant_name,
        contractor_cnes: this.invoicing_tiss_setting?.CNES,
        attendance_type: null,
        occupational_health: null,
        accident_indication: null,
        appointment_type: null,
        closing_reason: null,
        attendance_regime: null,
        observation: null,
        // total_value_procedures: 0,
        // total_amount_fess_and_rents: 0,
        // total_value_materials: 0,
        // total_value_opme: 0,
        // total_value_medicines: 0,
        // total_value_medicinal_gases: 0,
        // final_amount: 0,
        materialsProcedures: [],
        items: this.form?.items ?? []
      }
    },
    getOtherExpenseItems(items) {
      this.otherExpenseItems = items.filter(
            item =>
              item.id &&
              item.guide_type ===
                'App\\Models\\TissGuide\\TissGuideOtherExpense'
          )
    },
    async setSelectsFields() {
      if (this.tissGuide?.id) return
      const fields = {
        contracted_code_type: this.invoicing_tiss_setting?.provider_code
          ? this.contractedCodeTypes.find(
              option =>
                option.label === this.invoicing_tiss_setting?.provider_code
            )
          : null,
        beneficiary_name: this.patient?.name,
        national_health_card: this.patient?.cns,
        newborn_care: this.patient?.birthday
          ? this.moment(this.patient.birthday).add(1, 'M') >= this.moment()
          : false,

        attendance_character: this.invoicing_tiss_setting?.attendance_character
          ? this.attendancesCharacter.find(
              option =>
                option.label ===
                this.invoicing_tiss_setting?.attendance_character
            )
          : null,

        attendance_type: this.invoicing_tiss_setting?.attendance_type
          ? this.attendanceTypes.find(
              option =>
                option.label === this.invoicing_tiss_setting?.attendance_type
            )
          : null,

        accident_indication: this.invoicing_tiss_setting?.accident_indication
          ? this.accidentIndications.find(
              option =>
                option.label === this.invoicing_tiss_setting?.accident_indication
            )
          : null,

        appointment_type: this.invoicing_tiss_setting?.consultation_type
          ? this.consultationTypes.find(
              option =>
                option.label === this.invoicing_tiss_setting?.consultation_type
            )
          : null
      }

      this.form = {
        ...this.form,
        ...fields
      }
    },
    clearForm() {
      this.form = this.getDefaultForm()
      this.otherExpenseItems = []
      this.getPatientWallet()
      this.validated = false
    },
    /**
     * method modified due to reactivity trouble when refreshing tissGuide multi component level parameter
     * @param tissGuideItems
     * @returns {*}
     */
    filterProcedures(tissGuideItems) {
      const filteredItems = tissGuideItems.filter(
        tissGuideItem => !tissGuideItem.id || tissGuideItem.guide_id === this.form.id
      )
      if (this.form.items?.length) {
        filteredItems.map(filteredItem => {
          const formItem = this.form.items.filter(
            item => item.id === filteredItem.id
          )
          if (formItem) {
            filteredItem.quantity = formItem[0].quantity
            filteredItem.pathway = formItem[0].pathway
            filteredItem.technique = formItem[0].technique
          }
        })
      }
      return filteredItems
    },
    validProp(prop) {
      const requiredTissFields = Object.keys(this.healthPlanRequiredFields).length ? this.healthPlanRequiredFields : this.requiredFields
      if (!this.validated || !requiredTissFields[prop]) return null
      return !!this.form[prop]
    },
    isValidForm() {
      this.validated = true

      if (
        this.form.provider_guide &&
        (!this.form.provider_guide || this.form.provider_guide < 1)
      ) {
        this.$toast.error('O Nº da guia no prestador deve ser maior que zero.')
        return false
      }

      if (this.guideNumberExists) {
        this.$toast.error(
          'Este Nº de guia já existe, resolva o conflito para continuar.'
        )
        return false
      }

      if (this.form.tiss_invoice_id && this.hasItemWithoutProfessional()) {
        this.$toast.warning(
          'Não é possível atualizar/salvar guia com fatura relacionada sem que todos os itens adicionados estejam com profissional ativo e com valor total maior que R$0,00.'
        )
        return false
      }

      const requiredTissFields = Object.keys(this.healthPlanRequiredFields).length ? this.healthPlanRequiredFields : this.requiredFields

      const procedureKeys = [
        'execution_date',
        'start_time',
        'end_time',
        'pathway',
        'technique'
      ]

      if (
        Object.keys(requiredTissFields)
          .filter(key => procedureKeys.includes(key))
          .some(
            key =>
              requiredTissFields[key] &&
              (!this.form.items.length ||
                this.form.items.some(item => !item[key]))
          )
      ) {
        this.$toast.warning(
          'Existem campos obrigatórios não preenchidos nos procedimentos.'
        )
        return false
      }

      const spSadtKeys = [
        'provider_guide',
        'main_tab_number',
        'wallet_number',
        'wallet_expiration_date',
        'beneficiary_name',
        'accident_indication',
        'authorization_date',
        'password',
        'password_expiration_date',
        'contractor_name_requesting',
        'request_date',
        'attendance_character',
        'clinical_indication',
        'attendance_type'
      ]

      if (this.xml_version?.[0] ?? '' === '4') {
        spSadtKeys.push('attendance_regime');
        spSadtKeys.push('occupational_health');
      }

      return !Object.keys(requiredTissFields)
        .filter(key => spSadtKeys.includes(key))
        .some(key => requiredTissFields[key] && !this.form[key])
    },
    hasItemWithoutProfessional() {
      return (
        this.form.items.some(item => item?.professionals?.length === 0) ||
        this.form.items.some(item =>
          item?.professionals.some(professional => professional?.value <= 0)
        )
      )
    },
    getSelectedValue() {
      return {
        id: this.form.id,
        patient_id: this.patient.id,
        clinic_id: this.clinic.id,
        invoicing_tiss_setting_id: this.form.invoicing_tiss_setting_id,
        appointment_id: this.form.appointment_id,
        tiss_guide_id: this.form.tiss_guide_id,
        procedure_id: this.form.procedure_id,
        bank_account_id: this.form.bank_account_id,
        guide_issue_date: this.moment(this.form.guide_issue_date).format(
          'YYYY-MM-DD'
        ),
        request_id: this.form.request_id,
        situation: this.form.situation,
        tiss_invoice_id:
          this.form.tiss_invoice_id?.value || this.form.tiss_invoice_id,
        ans: this.form.ans,
        provider_guide: this.form.provider_guide,
        main_tab_number: this.form.main_tab_number,
        authorization_date: this.form.authorization_date,
        password: this.form.password,
        password_expiration_date: this.form.password_expiration_date,
        operator_guide_number: this.form.operator_guide_number,
        wallet_number: this.form.wallet_number,
        wallet_expiration_date: this.form.wallet_expiration_date,
        beneficiary_name: this.form.beneficiary_name,
        national_health_card: this.form.national_health_card,
        newborn_care: this.form.newborn_care,
        professional_id: this.form.professional_id,
        operator_code: this.form.operator_code,
        contractor_name_requesting: this.form.contractor_name_requesting,
        professional_name_performing: this.form.professional_name_performing,
        requesting_professional_type: this.form.requesting_professional_type,
        requesting_professional_name: this.form.requesting_professional_name,
        professional_council: this.form.professional_council?.value
          ? this.form.professional_council.value
          : null,
        professional_council_registration:
          this.form.professional_council_registration,
        uf: this.form.uf?.value,
        cbo_code: this.form.cbo_code?.value,
        attendance_character: this.form.attendance_character?.value
          ? this.form.attendance_character.value
          : null,
        request_date: this.form.request_date,
        clinical_indication: this.form.clinical_indication,
        contracted_code_type: this.form.contracted_code_type?.value,
        contracted_operator_code: this.form.contracted_operator_code,
        performing_contractor_name: this.form.performing_contractor_name,
        contractor_cnes: this.form.contractor_cnes,
        attendance_type: this.form.attendance_type?.value
          ? this.form.attendance_type.value
          : null,
        accident_indication: this.form.accident_indication?.value
          ? this.form.accident_indication.value
          : null,
        appointment_type: this.form.appointment_type?.value
          ? this.form.appointment_type.value
          : null,
        closing_reason: this.form.closing_reason?.value
          ? this.form.closing_reason.value
          : null,
        attendance_regime: this.form.attendance_regime?.value
          ? this.form.attendance_regime.value
          : null,
        occupational_health: this.form.occupational_health?.value ?? null,
        observation: this.form.observation,
        materialsProcedures: this.form.materialsProcedures,
        procedureRequests: this.form.procedureRequests,
        items: this.form.items.map(item => ({
          ...item,
          pathway: item.pathway?.value,
          technique: item.technique?.value
        }))
      }
    },
    requestingProfessionalTypeChange() {
      this.form.professional_id = null
      this.form.professional_selected = null
      this.form.professional_council = null
      this.form.professional_council_registration = null
      this.form.cbo_code = null
      this.cbo_description = null
      this.form.uf = null
    },
    async createGuide() {
      if (!this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      this.alreadySent = true
      const isLoading = this.$loading.show()
      try {
        const payload = this.getSelectedValue()
        const checkProfessionals = this.checkProfessionals(payload?.items)

        if (checkProfessionals) {
          const { data } = await this.api.createSPSADTGuide(payload)
          this.$emit('refresh-tiss-guides', {
            id: data.id,
            guide_id: this.tissGuide.id
          })
          this.$toast.success('Guia SP/SADT criada com sucesso!')
          this.closeModal()
        } else {
          this.$swal.fire({
            title: 'Aviso',
            html: `
              <div>
                Você possui um ou mais procedimentos sem um médico executante.
                <span style="font-weight: bold; color: #305BF2">
                  Defina um médico executante para prosseguir!
                </span>
              </div>
            `,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: 'transparent',
            cancelButtonText: '<span style="color: darkblue;">Voltar</span>',
            cancelButtonHoverColor: '#ff0000',
            showCloseButton: true,
            reverseButtons: true,
            customClass: {
              popup: 'custom-swal-professional-guide'
            }
          })
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.alreadySent = false
        isLoading.hide()
      }
    },
    async updateTissGuide(notReopen) {
      if (!this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }

      this.alreadySent = true
      const isLoading = this.$loading.show()
      try {
        const tissGuideId = this.tissGuide?.id ?? null
        const payload = this.getSelectedValue()
        const checkProfessionals = this.checkProfessionals(payload?.items)
        if (checkProfessionals) {
          this.form.clinic_id = this.clinic.id
          const { data } = await this.api.updateSPSADTGuide(
            this.tissGuide.guide.id,
            payload
          )
          this.$emit('refresh-tiss-guides', {
            id: data.id,
            guide_id: tissGuideId ?? null,
            notReopen: notReopen
          })
          if (this.tissGuide?.guide?.updated_at)
            this.tissGuide.guide.updated_at = this.moment().toDate()
          this.$toast.success('Guia SP/SADT atualizada com sucesso!')
        } else {
          this.$swal.fire({
            title: 'Aviso',
            html: `
              <div>
                Você possui um ou mais procedimentos sem um médico executante.
                <span style="font-weight: bold; color: #305BF2">
                  Defina um médico executante para prosseguir!
                </span>
              </div>
            `,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: 'transparent',
            cancelButtonText: '<span style="color: darkblue;">Voltar</span>',
            cancelButtonHoverColor: '#ff0000',
            showCloseButton: true,
            reverseButtons: true,
            customClass: {
              popup: 'custom-swal-professional-guide'
            }
          })
        }
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.alreadySent = false
        isLoading.hide()
      }
    },
    totalByCD(cd) {
      return this.otherExpenseItems
        .filter(item => String(item.cd).padStart(2, '0') === cd)
        ?.reduce((acc, item) => {
          return acc + (item.unitary_value * item.quantity)
        }, 0)
    },
    totalProcedures() {
      return this.form.items?.reduce((acc, item) => {
        return acc + item.total_value
      }, 0)
    },
    total() {
      const totalProcedures = this.form.items?.reduce((acc, item) => {
        return acc + item.total_value
      }, 0)

      const totalOtherExpenses = this.otherExpenseItems?.reduce((acc, item) => {
        return acc + (item.unitary_value * item.quantity)
      }, 0)

      return totalProcedures + totalOtherExpenses
    },
    print() {
      this.$emit('print', this.tissGuide)
    },
    async closeModal(canCallExclude = false) {
      // if(!this.alreadyUpdated && canCallExclude) await this.confirmDelete()
      // if(this.canCloseModal){
      this.clearForm()
      this.form.items = []
      this.$emit('onHide')
      this.$bvModal.hide('spsadt-guide-modal')
      // }
    },
    async confirmDelete() {
      await this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir a Guia <span>SP/SADT - ${this.tissGuide?.patient?.name}</span>? Esta ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.$emit('deleteTissGuide', this.tissGuide, true)
          this.canCloseModal = true
        } else this.canCloseModal = false
      })
    },
    async onShow() {
      this.attendanceTypes = this.xml_version && this.xml_version[0] === '4' ? require('./../Data/attendanceTypes4.json') : require('./../Data/attendanceTypes.json');
      await this.getHealthPlanRequiredFields()
      this.getPatientWallet()
      this.canCloseModal = true
      if (!this.tissGuide?.id) {
        await this.setSelectsFields()
      }
      this.getOtherExpenseItems(this.tissGuide.items);
    },
    getPatientWallet() {
      if (this.tissGuide?.id) return

      const personPlan = this.patient?.person_health_plans?.find(
        plan => plan?.health_plan_id === this.healthPlan?.id
      )

      this.form.wallet_number = personPlan?.registration
      this.form.wallet_expiration_date = this.moment(
        personPlan?.valid_until
      ).toDate()
    },
    selectContracted(value) {
      this.form.contractor_name_requesting = value?.label
      this.form.operator_code = value?.operator_code
    },
    selectContractor(value) {
      this.form.performing_contractor_name = value?.label
      this.form.contracted_operator_code = value?.operator_code.replace(
        /\D/g,
        ''
      )
      this.form.contractor_cnes = value?.cnes
      this.form.contracted_code_type = this.contractedCodeTypes.find(
        option => option.value === value?.code_type
      )
    },
    async startOtherExpensesGuide() {
      await this.updateTissGuide(true)
      this.canCloseModal = false
      this.$bvModal.hide('spsadt-guide-modal')
      this.$emit('createOtherExpensesGuide', this.form?.provider_guide)
    },
    startOpenOtherExpensesGuide(expensableId) {
      this.updateTissGuide(true)
      this.closeModal(true)
      this.$emit('openOtherExpensesGuide', expensableId)
    },
    addOrUpdateTissGuideItem(tissGuideItem) {      
      const items = Object.assign([], this.form.items ?? [])
      tissGuideItem.index > -1
        ? items.splice(tissGuideItem.index, 1, tissGuideItem)
        : items.push(tissGuideItem)
      this.form = { ...this.form, items }
    },
    deleteTissGuideItem(index) {
      const items = Object.assign([], this.form.items ?? [])
      items.splice(index, 1)
      this.form = { ...this.form, items }
    },
    checkProfessionals(items) {
      if (items.length === 0) return true
      let check = true
      items.forEach(el => {
        if (el.professionals?.some(el => !el.professional_id)) {
          check = false
        }
      })
      return check
    },
    async getHealthPlanRequiredFields() {
      if(this.clinicHealthPlanId){
        try {
          this.api.getHealthPlanRequiredFields(this.clinicHealthPlanId).then(({ data }) => {
            if(data.length){
              const temp = [];
              data.forEach(item => {
                temp[item.name] = Boolean(item.pivot.active);
              });
              this.healthPlanRequiredFields = temp;
            }
          })
        } catch (error) {
          console.error('Erro ao buscar requiredFields: ' + error)
        }
      }
    }
  },
  watch: {
    tissGuide: {
      async handler(newValue) {
        if (newValue.clinic_id) {
          this.api
            .getBeneficiariesByClinicId(newValue.clinic_id)
            .then(({ data }) => {
              if (data && data.length > 0) {
                this.beneficiariesList = data
              }
            })
        }
        const oldForm = this.form
        if (
          newValue.guide_type === 'App\\Models\\TissGuide\\TissGuideSpSadt' &&
          !oldForm.updated_at
        ) {
          this.form = {
            ...this.form,
            ...newValue.guide
          }
          this.form.guide_issue_date = newValue?.guide?.guide_issue_date
            ? this.moment(newValue.guide.guide_issue_date).toDate()
            : null

          this.form.items = oldForm.items?.length
            ? oldForm.items
            : this.filterProcedures(newValue.items)

          this.form.provider_guide = newValue.provider_guide
          this.form.tiss_guide_id = newValue.id
          this.form.tiss_invoice_id = newValue.tiss_invoice_id
          this.form.invoicing_tiss_setting_id =
            newValue.invoicing_tiss_setting_id
          this.form.materialsProcedures = newValue.materials_procedures
          this.form.situation = newValue.situation
          this.form.authorization_date = this.moment(
            newValue.authorization_date
          ).toDate()
          this.form.password_expiration_date = this.moment(
            this.form.password_expiration_date
          ).toDate()
          this.form.wallet_expiration_date = this.moment(
            this.form.wallet_expiration_date
          ).toDate()
          this.form.request_date = this.moment(this.form.request_date).toDate()
          this.form.situation = newValue.situation
          this.form.professional_council_registration =
            newValue?.guide?.professional_council_registration ??
            oldForm.professional_council_registration
          this.form.professional_council = newValue?.guide?.professional_council
            ? this.professionalCouncils.find(
                option => option.value === newValue.guide.professional_council
              )
            : oldForm.professional_council
          this.form.uf = newValue?.guide?.uf
            ? this.statesUf.find(option => option.value === newValue.guide.uf)
            : oldForm.uf
          this.form.cbo_code = newValue?.guide?.cbo_code
            ? this.cbos.find(option => option.value === newValue.guide.cbo_code)
            : oldForm.cbo_code
          this.form.attendance_character = this.attendancesCharacter.find(
            option => option.value === newValue.guide.attendance_character
          )
          this.form.contracted_code_type = this.contractedCodeTypes.find(
            option => option.value === newValue.guide.contracted_code_type
          )
          this.form.attendance_type = this.attendanceTypes.find(
            option => option.value === newValue.guide.attendance_type
          )
          this.form.accident_indication = this.accidentIndications.find(
            option => option.value === newValue.guide.accident_indication
          )
          this.form.appointment_type = this.consultationTypes.find(
            option => option.value === newValue.guide.appointment_type
          )
          this.form.closing_reason = this.closingReasons.find(
            option => option.value === newValue.guide.closing_reason
          )
          this.form.attendance_regime = this.attendanceRegimes.find(
            option => option.value === newValue.guide.attendance_regime
          )
          this.form.occupational_health = this.occupationalHealthOptions.find(
            option => option.value === newValue.guide.occupational_health
          )

          this.getOtherExpenseItems(newValue.items);

        } else if (newValue.items?.length && !oldForm.updated_at) {

          !this.form.ans && (this.form = this.getDefaultForm())
          this.getPatientWallet()
          this.form.invoicing_tiss_setting_id = newValue.invoicing_tiss_setting_id
          this.form.items = this.filterProcedures(newValue.items)
          this.getOtherExpenseItems(newValue.items);
        }
        await this.setSelectsFields()
      },
      immediate: true,
      deep: true
    },
    guideNumber() {
      if (this.tissGuide?.id) return
      this.form.provider_guide = this.guideNumber
      this.form = this.getDefaultForm()
      this.getPatientWallet()
    },
    'form.provider_guide': {
      handler(newValue) {
        if (!newValue) return
        const form = {
          id: this.tissGuide?.id,
          provider_guide: newValue,
          patient: {
            name: this.form.beneficiary_name
          }
        }
        this.$emit('update-form', {
          form: form,
          providerGuide: this.providerGuideUpdated ?? this.guideNumber
        })
      },
      deep: true,
      immediate: true
    },
    providerGuideUpdated: {
      handler(newValue) {
        if (!newValue) return
        this.form.provider_guide = newValue
      },
      immediate: true
    },
    invoicing_tiss_setting: {
      async handler(value) {
        if (!value) return
        await this.setSelectsFields()
        !this.form.invoicing_tiss_setting_id &&
          (this.form.invoicing_tiss_setting_id = value.id)
        this.xml_version = value?.tiss_information?.XML_version
        this.form.invoicing_tiss_setting_id = value?.id
      },
      deep: true,
      immediate: true
    },
    patient: {
      handler(value) {
        if (!value) return
        this.getPatientWallet()
        this.form.beneficiary_name = value.name
        this.form.national_health_card =
          this.form.national_health_card || value?.cns
        this.form.newborn_care =
          this.form.newborn_care || this.patient?.birthday
            ? this.moment(this.patient.birthday).add(1, 'M') >= this.moment()
            : false
      },
      deep: true,
      immediate: true
    },
    healthPlan: {
      handler(value) {
        if (!value?.ans_code || this.form.ans) return
          this.form.ans = value.ans_code
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#spsadt-guide-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;
    }

    .wh-button {
      color: var(--blue-500);
    }
  }

  .inline-header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  .width-80 {
    width: 80%;
  }

  .width-85 {
    width: 85%;
  }

  .width-95 {
    width: 95%;
  }

  label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0a184c;
    margin: 0;
  }

  .form-title {
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }
  .hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .check-disable {
    background-color: var(--neutral-100) !important;
    border: 1px solid var(--neutral-300) !important;
  }
}
</style>
